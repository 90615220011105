import AccountDeletedIcon from '../icons/AccountDeletedIcon';
import { Button } from '../ui/button';
import BaseDialog from './BaseDialog';

export default function AccountDeletedDialog({ isOpen, onClose }: { isOpen: boolean; onClose: () => void }) {
  return (
    <BaseDialog
      isOpen={isOpen}
      onClose={onClose}
      icon={<AccountDeletedIcon />}
      title="Account deleted"
      description="Your Custody Navigator account has been successfully deleted."
      titleClassName="text-green-dark"
      buttons={
        <Button
          type="button"
          onClick={onClose}
          className="text-beige-light bg-green-dark flex items-center justify-center rounded-full border px-8 py-4 text-sm font-medium"
        >
          Dismiss
        </Button>
      }
    />
  );
}
