import { cn } from '~/lib/utils';
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle } from '../ui/dialog';

interface BaseDialogProps {
  isOpen: boolean;
  onClose: () => void;
  icon: React.ReactNode;
  title: string;
  description: string;
  buttons: React.ReactNode;
  titleClassName?: string;
}

export default function BaseDialog({
  isOpen,
  onClose,
  icon,
  title,
  description,
  buttons,
  titleClassName,
}: BaseDialogProps) {
  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="w-80 rounded-2xl border border-taupe-light-75 bg-neutral-linen p-8">
        <DialogHeader>
          <DialogTitle
            className={cn(
              'flex flex-col items-center justify-center space-y-8 font-display text-2xl font-extrabold text-green-dark',
              titleClassName
            )}
          >
            {icon}
            <span className="text-center">{title}</span>
          </DialogTitle>
        </DialogHeader>
        <DialogDescription className="text-center text-sm font-normal text-green-primary-text">
          {description}
        </DialogDescription>
        <DialogFooter className="flex items-center justify-center space-y-2 sm:justify-center">{buttons}</DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
