import AccountCreatedSuccessIcon from '../icons/AccountCreatedSuccessIcon';
import { Button } from '../ui/button';
import BaseDialog from './BaseDialog';

export default function AccountCreatedSuccessDialog({ isOpen, onClose }: { isOpen: boolean; onClose: () => void }) {
  return (
    <BaseDialog
      isOpen={isOpen}
      onClose={onClose}
      icon={<AccountCreatedSuccessIcon />}
      title="You're in!"
      description="You're one step closer to finding the right parenting schedule for your family."
      titleClassName="text-green-dark"
      buttons={
        <Button
          onClick={onClose}
          type="button"
          className="text-beige-light bg-green-dark flex items-center justify-center rounded-full border px-8 py-4 text-sm font-medium"
        >
          Continue
        </Button>
      }
    />
  );
}
