export default function AccountDeletedIcon() {
  return (
    <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="100" height="100" rx="50" fill="#EDDFCC" />
      <g clipPath="url(#clip0_2776_5337)">
        <path
          d="M44.7443 28H55.2554C56.3118 28 57.2808 28.5844 57.7523 29.5211L58.3808 30.75H66.7618C68.307 30.75 69.5554 31.9789 69.5554 33.5C69.5554 35.0211 68.307 36.25 66.7618 36.25H33.238C31.6927 36.25 30.4443 35.0211 30.4443 33.5C30.4443 31.9789 31.6927 30.75 33.238 30.75H41.6189L42.2475 29.5211C42.7189 28.5844 43.688 28 44.7443 28ZM33.238 39H66.7618V66.5C66.7618 69.5336 64.2562 72 61.1745 72H38.8253C35.7435 72 33.238 69.5336 33.238 66.5V39ZM59.865 50.7734C60.6856 49.9656 60.6856 48.6594 59.865 47.8602C59.0443 47.0609 57.7174 47.0523 56.9054 47.8602L47.215 57.3992L43.1118 53.3602C42.2912 52.5523 40.9642 52.5523 40.1523 53.3602C39.3404 54.168 39.3316 55.4742 40.1523 56.2734L45.7396 61.7734C46.5602 62.5812 47.8872 62.5812 48.6991 61.7734L59.865 50.7734Z"
          fill="#777066"
        />
      </g>
      <defs>
        <clipPath id="clip0_2776_5337">
          <rect width="39.1111" height="44" fill="white" transform="translate(30.4443 28)" />
        </clipPath>
      </defs>
    </svg>
  );
}
