import React from 'react';
import AccountCreatedSuccessDialog from '../dialogs/AccountCreatedSuccessDialog';
import { useToast } from '../ui/use-toast';
import { notificationData, NotificationType } from '~/constants/notification';
import AccountDeletedDialog from '../dialogs/AccountDeletedDialog';
import CautionDialog from '../dialogs/CautionDialog';

const modalNotifications = [
  NotificationType.ACCOUNT_DELETED_SUCCESS,
  NotificationType.AUTH_FAILED,
  NotificationType.FIRST_HOLIDAY_CREATED_SUCCESS,
  NotificationType.SIGNUP_SUCCESS,
];

export default function Notification({ notification }: { notification: NotificationType | null }) {
  const { toast } = useToast();
  const [dialogState, setDialogState] = React.useState({
    accountCreated: notification === NotificationType.SIGNUP_SUCCESS,
    accountCreatedError: notification === NotificationType.AUTH_FAILED,
    accountDeleted: notification === NotificationType.ACCOUNT_DELETED_SUCCESS,
    firstHolidaySuccess: notification === NotificationType.FIRST_HOLIDAY_CREATED_SUCCESS,
  });

  React.useEffect(() => {
    if (notification && !modalNotifications.includes(notification)) {
      const notificationProps = notificationData[notification];
      setTimeout(() => {
        toast(notificationProps);
      }, 500);
    }
  }, [notification, toast]);

  return (
    <>
      <AccountCreatedSuccessDialog
        isOpen={dialogState.accountCreated}
        onClose={() => setDialogState({ ...dialogState, accountCreated: false })}
      />
      <AccountDeletedDialog
        isOpen={dialogState.accountDeleted}
        onClose={() => setDialogState({ ...dialogState, accountDeleted: false })}
      />
      <CautionDialog
        callToAction={[
          {
            onClick: () => setDialogState({ ...dialogState, accountCreatedError: false }),
            text: 'Dismiss',
            isPrimary: true,
          },
        ]}
        description="Something went wrong during the authentication process. Please try creating your account again."
        isOpen={dialogState.accountCreatedError}
        onClose={() => setDialogState({ ...dialogState, accountCreatedError: false })}
        title="Account creation failed"
      />
    </>
  );
}
