export default function CautionIcon() {
  return (
    <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="50" cy="50" r="50" fill="#FAE5D3" />
      <path
        d="M50 28.25L72 66.75H28L50 28.25ZM52.0625 42H47.9375V44.0625V53.6875V55.75H52.0625V53.6875V44.0625V42ZM47.9375 58.5V62.625H52.0625V58.5H47.9375Z"
        fill="#B2622B"
      />
    </svg>
  );
}
