export default function AccountCreatedSuccessIcon() {
  return (
    <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="50" cy="50" r="50" fill="#DEF1E7" />
      <path
        d="M30.75 31.25C30.75 29.7891 31.9531 28.5 33.5 28.5C34.9609 28.5 36.25 29.7891 36.25 31.25C36.25 32.7969 34.9609 34 33.5 34C31.9531 34 30.75 32.7969 30.75 31.25ZM66.5 42.25C66.5 40.7891 67.7031 39.5 69.25 39.5C70.7109 39.5 72 40.7891 72 42.25C72 43.7969 70.7109 45 69.25 45C67.7031 45 66.5 43.7969 66.5 42.25ZM69.25 64.25C70.7109 64.25 72 65.5391 72 67C72 68.5469 70.7109 69.75 69.25 69.75C67.7031 69.75 66.5 68.5469 66.5 67C66.5 65.5391 67.7031 64.25 69.25 64.25ZM40.8906 40.1875L42.3516 38.7266L43.0391 38.0391C44.5 36.5781 45.3594 34.7734 45.6172 32.7109L45.875 30.3906L49.9141 30.8203L49.6562 33.1406C49.3984 36.1484 48.0234 38.8984 45.9609 40.9609L45.2734 41.6484L43.8125 43.1094L40.8906 40.1875ZM58.8516 55.2266L59.5391 54.5391C61.6016 52.4766 64.3516 51.1016 67.2734 50.8438L69.6797 50.5859L70.1094 54.625L67.7891 54.8828C65.7266 55.1406 63.8359 56 62.4609 57.4609L61.7734 58.1484L60.3125 59.6094L57.3906 56.6875L58.8516 55.2266ZM71.2266 32.1953L70.3672 32.8828C69.0781 34 67.4453 34.5156 65.8125 34.5156C64.3516 34.4297 63.1484 35.5469 63.0625 37.0078L62.9766 39.5859C62.7188 43.1953 59.625 46.0312 56.0156 45.9453C55.3281 45.9453 54.7266 46.1172 54.2109 46.5469L53.3516 47.3203L51.8047 48.6094L49.1406 45.5156L50.6875 44.1406L51.5469 43.4531C52.8359 42.3359 54.4688 41.8203 56.1016 41.8203C57.5625 41.9062 58.7656 40.7891 58.8516 39.3281L59.0234 36.75C59.1953 33.1406 62.2891 30.3047 65.8984 30.3906C66.5859 30.3906 67.1875 30.2188 67.7031 29.7891L68.5625 29.0156L71.2266 32.1953ZM30.75 72.5L28 69.75L31.2656 61.3281L39.1719 69.2344L30.75 72.5ZM42.0078 68.1172L32.3828 58.4922L34.3594 53.3359L47.1641 66.1406L42.0078 68.1172ZM49.9141 65.0234L35.4766 50.5859L37.625 45L55.5 62.875L49.9141 65.0234Z"
        fill="#0CA787"
      />
    </svg>
  );
}
